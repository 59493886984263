import React from "react";
import { graphql } from "gatsby";
import { mapEdgesToNodes, filterOutDocsWithoutSlugs } from "../lib/helpers";
import GraphQLErrorList from "../components/GraphQLErrorList";
import FigCardGrid from "../components/FigCardGrid";
import SEO from "../components/SEO";
import LayoutContainer, { PageTypes } from "../containers/LayoutContainer";

export const query = graphql`
  query GridPageQuery {
    # site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
    #   title
    #   description
    #   keywords
    # }

    figs: allSanityFig(
      sort: { fields: [number], order: DESC }
      filter: { slug: { current: { ne: null } }, number: { ne: null } }
    ) {
      edges {
        node {
          id
          number
          title
          inactive
          slug {
            current
          }
          categories {
            slug {
              current
            }
          }
          isSeparateThumbnail
          isVideoThumbnail
          thumbnail {
            ...ImageWithPreview
            alt
          }
          thumbnailVideo {
            vimeoId
          }
          mainImage {
            ...ImageWithPreview
            alt
          }
        }
      }
    }
  }
`;

const GridPage = (props) => {
  const { data, errors, location } = props;
  if (errors) {
    return <GraphQLErrorList errors={errors} />;
  }
  const categoryFilter = location.search.substring(1);

  const categoryFilterFn = ({ categories }) => {
    const categorySlugs = categories.map((category) => category.slug?.current);
    if (!categoryFilter) return true;
    if (categorySlugs.includes(categoryFilter)) return true;
  };

  const figNodes = (data || {}).figs
    ? mapEdgesToNodes(data.figs).filter(filterOutDocsWithoutSlugs).filter(categoryFilterFn)
    : [];

  return (
    <>
      <SEO title="Figur Grid" />
      <LayoutContainer pageType={PageTypes.Grid} location={location}>
        {figNodes && <FigCardGrid nodes={figNodes} filter={categoryFilter} />}
      </LayoutContainer>
    </>
  );
};

export default GridPage;
